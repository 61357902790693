import { Remove } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
    Autocomplete,
    Checkbox,
    CircularProgress,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    createFilterOptions,
} from "@mui/material";
import { Button } from "components/shared";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { getProducts } from "services/productService";
import BundleCollectionWeight from "components/businesses/ecommerce/specials/collections/BundleCollectionWeight";
import CategoryBrandsAutocomplete from "components/businesses/ecommerce/specials/form-fields/CategoryBrandsAutocomplete";
import { createCategorySearchData } from "components/businesses/ecommerce/specials/modals/data/productSearchData";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function BothOption({
    filter,
    filterIndex,
    selectedCategories,
    setSelectedCategories,
    selectedBrands,
    setSelectedBrands,
    selectedProducts,
    weightFilter,
    setWeightFilter,
    weightValue,
    setWeightValue,
    weightFilterErrorMessage,
    setWeightFilterErrorMessage,
    weightValueErrorMessage,
    setWeightValueErrorMessage,
}) {
    // * Formik Context
    const formik = useFormikContext();
    const { setFieldValue } = formik;
    // * Getting business id for elastic search
    const business = useAppSelector((state) => state.business.info);
    const businessId = business?.id;
    // * Setting state for autocomplete values
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options?.length === 0;
    const [isDisabled, setIsDisabled] = useState(filter.includedBrandIds);
    const [buttonClicked, setButtonClicked] = useState(false);

    // * Fetch data when autocomplete is opened
    useEffect(() => {
        let active = true;
        const data = createCategorySearchData(businessId);

        if (!loading) {
            return undefined;
        }
        (async () => {
            try {
                const response = await getProducts(data);
                const categories = response?.aggregations?.categories?.buckets.filter(
                    (category) => category.key !== ""
                );
                if (active) {
                    setOptions(categories);
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    // * Remove options when closed
    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    // * Limit the list to 10 items at a time
    const OPTIONS_LIMIT = 10;
    const filterOptions = createFilterOptions({
        limit: OPTIONS_LIMIT,
    });

    // * Checking if filter changes
    useEffect(() => {
        if (selectedCategories.length >= 1) {
            setIsDisabled(false);
        } else {
            setFieldValue(
                `bundleQualifiers[0].customCollection.filters[${filterIndex}].includedBrandIds`,
                []
            );
            setIsDisabled(true);
            setButtonClicked(false)
        }
    }, [filter]);

    // * Add brands to filter
    function handleAddBrands() {
        setFieldValue(
            `bundleQualifiers[0].customCollection.filters[${filterIndex}].includedBrandIds`,
            []
        );
        setButtonClicked(true)
    }

    // * Remove brands from filter
    function handleRemoveBrands() {
        setFieldValue(
            `bundleQualifiers[0].customCollection.filters[${filterIndex}].includedBrandIds`,
            null
        );
        setButtonClicked(false)
    }

    return (
        <div>
            <div style={{ display: "flex", columnGap: 10 }}>
                <div style={{ flex: 1, margin: "8px 0", minWidth: "200px" }}>
                    <CategoryBrandsAutocomplete
                        businessId={businessId}
                        setSelectedBrands={setSelectedBrands}
                        selectedBrands={selectedBrands}
                        selectedProducts={selectedProducts}
                        selectedCategories={selectedCategories}
                    />
                </div>

                <div>
                    <BundleCollectionWeight
                        weightFilter={weightFilter}
                        setWeightFilter={setWeightFilter}
                        weightValue={weightValue}
                        setWeightValue={setWeightValue}
                        weightFilterErrorMessage={weightFilterErrorMessage}
                        weightValueErrorMessage={weightValueErrorMessage}
                        setWeightValueErrorMessage={setWeightValueErrorMessage}
                        setWeightFilterErrorMessage={setWeightFilterErrorMessage}
                    />
                </div>
            </div>

            <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
                <div style={{ flex: 1, margin: "8px 0", minWidth: "200px" }}>
                    <Autocomplete
                        id="included-categories"
                        name={`bundleQualifiers[0].customCollection.filters[${filterIndex}].includedCategories`}
                        multiple
                        limitTags={1}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        value={
                            Array.isArray(selectedCategories)
                                ? selectedCategories
                                : [selectedCategories]
                        }
                        isOptionEqualToValue={(option, value) => option?.key === value}
                        onChange={(event, value) => {
                            const selectedKeys = value.map(option => 
                              typeof option === 'object' && option.key ? option.key : option 
                            );
                            setSelectedCategories(selectedKeys); 
                          }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => {
                            if (typeof option === 'object' && option.key) {
                              return option.key;
                            } else if (typeof option === 'string') {
                              return option; 
                            }
                            return ''; // Default case if option is neither an object with 'key' nor a string
                          }}
                        options={options}
                        filterOptions={filterOptions}
                        loading={loading}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option?.key}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Included Categories"
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        width: "100%",
                                        paddingRight: "0px",
                                        overflowY: "auto",
                                    },
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>

            </div>


        </div>
    );
}
